<template>
  <div class="">
    <div class="container mx-auto">
      <div class="lg:flex lg:justify-between mb-8">
        <div>
          <Intro msg="Eventi archiviati" />
        </div>
      </div>
    </div>

    <Read :only_passed="true" :only_published="false" />
  </div>
</template>

<script>
import Intro from "@/components/component/Intro.vue";
import Read from "@/components/events/Read.vue";

export default {
  name: "ReadEvents",
  components: {
    Intro,
    Read,
  },
  methods: {},
};
</script>
